/* eslint-disable */
import React from "react";
import { FaFacebookF } from "react-icons/fa";
import { FiInstagram } from "react-icons/fi";
import { IoLogoTwitter } from "react-icons/io";
import "./footer.css";

const Footer = () => {
  return (
    <footer>
      <a href="#" className="footer__logo">
        Brampton GAMER
      </a>
      <div className="footer__copyright">
        <small>&copy; Brampton GAMER. All rights reserved.</small>
      </div>
    </footer>
  );
};

export default Footer;
