/* eslint-disable */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlineHome, AiOutlineInfoCircle } from "react-icons/ai";
import { RiContactsBookLine } from "react-icons/ri";
import { MdOutlinePrivacyTip } from "react-icons/md";
import "./nav.css";

const Nav = () => {
  return (
    <nav>
      <Link
        to="/"
        href="#"
      >
        <AiOutlineHome />
      </Link>
      {/* <Link
        to="/about"
        href="#about"
        className={currentURL === "http://localhost:3000/about" ? "active" : ""}
      >
        <AiOutlineInfoCircle />
      </Link> */}
      <Link
        to="/contact"
        href="#contact"
      >
        <RiContactsBookLine />
      </Link>
      <Link
        to="/privacy-policy"
        href="#privacy"
      >
        <MdOutlinePrivacyTip />
      </Link>
    </nav>
  );
};

export default Nav;
