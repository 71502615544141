import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Header from "./pages/Header/Header";
import About from "./pages/About/About";
import Privacy from "./pages/Privacy/Privacy";
import Contact from "./pages/Contact/Contact";

import Nav from "./components/nav/Nav";
import Footer from "./components/footer/Footer";
import "./App.css";

function App() {
  return (
    <Router>
      <Nav />
      <Routes>
        <Route path="/" element={<Header />} />
        <Route path="/about" element={<About />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
