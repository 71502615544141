/* eslint-disable no-unused-vars */
import React from "react";
import { useRef } from "react";

import { MdOutlineEmail } from "react-icons/md";
// import emailjs from "emailjs-com";
import "./contact.css";

const Contact = () => {
  // const form = useRef();

  // const sendEmail = (e) => {
  //   e.preventDefault();

  //   emailjs.sendForm(
  //     "service_znhf5yo",
  //     "template_h9o35az",
  //     form.current,
  //     "2seRX-SCVJqQPyPjv"
  //   );

  //   e.target.reset();
  // };

  return (
    <section id="contact">
      <h5>Get In Touch</h5>
      <h2>Contact Us</h2>

      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <MdOutlineEmail className="contact__option-icon" />
            <h4>Email</h4>
            <h5>support@bramptongamer.com</h5>
            <a
              href="mailto:support@bramptongamer.com"
              rel="noreferrer"
              target="_blank"
            >
              Send a message
            </a>
          </article>
          {/* <article className="contact__option">
            <RiMessengerLine className="contact__option-icon" />
            <h4>Messenger</h4>
            <h5>fb_usename</h5>
            <a
              href="https://m.me/fb_usename"
              rel="noreferrer"
              target="_blank"
            >
              Send a message
            </a>
          </article> */}
          {/* <article className="contact__option">
            <BsWhatsapp className="contact__option-icon" />
            <h4>WhatsApp</h4>
            <h5>+923224587626</h5>
            <a
              href="https://api.whatsapp.com/send?phone=+923224587626"
              rel="noreferrer"
              target="_blank"
            >
              Send a message
            </a>
          </article> */}
        </div>
        {/* <form ref={form} onSubmit={sendEmail}>
          <input
            type="text"
            name="name"
            placeholder="Your Full Name"
            required
          />
          <input type="email" name="email" placeholder="Your Email" required />
          <textarea
            name="message"
            rows="7"
            placeholder="Your Message"
            required
          ></textarea>
          <button type="submit" className="btn btn-primary">
            Send Message
          </button>
        </form> */}
      </div>
    </section>
  );
};

export default Contact;
